<template>
    <div class="slice text-with-image">
        <prismic-image
            class="image"
            :class="{
                'is-pulled-left': slice.primary.image_alignment === 'Left',
                'is-pulled-right': slice.primary.image_alignment === 'Right',
            }"
            :field="slice.primary.image"
        />
        <prismic-rich-text
            class="content is-clearfix"
            :field="slice.primary.text_content"
        />
    </div>
</template>

<script>
export default {
    name: 'TextWithImage',

    props: {
        slice: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.text-with-image.slice {
    .image {
        max-width: 60%;
    }
}
</style>
