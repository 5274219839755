<template>
    <footer id="footer">
        <!-- eslint-disable vue/no-v-html -->
        <h4
            class="title is-4 has-text-white"
            v-text="$t('footer.header')"
        />
        <div
            class="footer-body content"
        >
            <a
                v-for="(link, idx) in this.$t('footer.links')"
                :key="idx"
                :href="link.link_href"
                :alt="link.link_alt"
            >
                <img
                    :src="link.link_img.url"
                    :alt="link.link_img.alt"
                >

            </a>
        </div>
    </footer>
</template>

<script>
import logoLink from '@/assets/canue-logo.png';

export default {
    data() {
        return {
            logo: logoLink,
        };
    },
};
</script>

<style lang="scss">
#footer {
    width: 100%;
    color: #fff;
    background-color: #505050;
    padding: 2rem 15%;
    text-align: center;

    // style all text from the CMS
    .content > * {
        color: #fff;
    }

    .content {
        background: white;
        border-radius: 0.5rem;
        display: inline-block;
        padding: 0 1rem;
        a {
            img {
                max-height: 5rem;
                max-width: 5rem;
                margin: 1rem;
                vertical-align: middle;
            }
        }
    }

    .logo-container {
        height: 4rem;
        width: 4rem;
        border-radius: 4rem;
        background-color: #fff;
        margin-bottom: 1.25rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
            height: 2.5rem;
            width: 2.5rem;
        }
    }

    a {
        color: #fff;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
