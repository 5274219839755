<template>
    <div id="home-page">
        <!-- eslint-disable vue/no-v-html -->
        <b-field
            data-aos="sticky-search-animation"
            data-aos-anchor=".search-container"
            data-aos-duration="200"
            data-aos-anchor-placement="bottom-top"
            class="sticky-search-container"
        >
            <div class="control">
                <b-tooltip
                    :label="$t('home.buttons.geolocationTooltip')"
                    type="is-tooltip"
                    position="is-right"
                >
                    <my-location-button
                        @success="goToMap"
                        @error="myLocationError"
                    >
                        {{ $t('home.buttons.geolocation') }}
                    </my-location-button>
                </b-tooltip>
            </div>
            <geocoder-search
                @select="goToMap"
            />
        </b-field>
        <div class="map-splash-container">
            <page-header />
            <div class="search-container px-5 py-5">
                <div class="header-icon">
                    <img
                        :src="$t('userAgreement.logo.url')"
                    >
                </div>
                <h2 class="subtitle is-4 is-size-3-tablet has-text-centered">
                    {{ $t('home.header') }}
                </h2>
                <div class="search-input-wrap mb-4">
                    <my-location-button
                        @success="goToMap"
                        @error="myLocationError"
                    />
                    <geocoder-search
                        @select="goToMap"
                    />
                </div>
                <div class="search-buttons is-flex is-align-items-center is-justify-content-center">
                    <b-button
                        @click="goToMap"
                    >
                        {{ $t('home.buttons.map') }}
                    </b-button>
                    <span
                        class="mx-5 my-2"
                        v-text="$t('home.buttons.or')"
                    />
                    <b-button
                        @click="startTutorial"
                    >
                        <b-icon
                            size="is-small"
                            class="tutorial-icon"
                            pack="mdi"
                            icon="information-outline"
                        />
                        {{ $t('home.buttons.tutorial') }}
                    </b-button>
                </div>
            </div>
            <a
                href="#info-section"
                class="explore is-size-7 has-text-dark"
            >
                {{ $t('home.scrollDown') }}
                <b-icon
                    pack="mdi"
                    type="is-primary"
                    size="is-medium"
                    icon="chevron-down"
                />
            </a>
        </div>
        <div
            id="info-section"
            class="info-section"
        >
            <h2
                class="info-header title is-3"
                v-text="$t('home.metricsHeader')"
            />
            <h3
                class="metrics-header subtitle is-5 is-uppercase"
                v-text="$t('home.metricsSubheader')"
            />
            <div class="metrics-container">
                <div
                    v-for="dimension in dimensions"
                    :key="dimension.fieldName"
                    class="metric"
                >
                    <div class="icon-container">
                        <b-icon
                            pack="mdi"
                            size="is-medium"
                            :icon="dimension.icon"
                        />
                    </div>
                    <h4
                        class="title is-5"
                        v-html="$t(`metrics.${dimension.fieldName}.title`)"
                    />
                    <div
                        class="metric-description"
                        v-html="$t(`metrics.${dimension.fieldName}.description`)"
                    />
                </div>
            </div>
            <page-footer />
        </div>
    </div>
</template>

<script>
import EventBus from '@/eventBus';
import PageFooter from '@/components/PageFooter';
import GeocoderSearch from '@/components/GeocoderSearch.vue';
import PageHeader from '@/components/PageHeader';
import MyLocationButton from '@/components/MyLocationButton.vue';
import metricsCollection from '@/scoreMetrics';

export default {
    components: {
        GeocoderSearch,
        MyLocationButton,
        PageFooter,
        PageHeader,
    },

    data: () => ({
        dimensions: metricsCollection.metrics,
    }),

    mounted() {
        if (this.$t('analyticsCode')) {
            // use the script element that CANUE has in prismic
            const gaScript = document.createRange().createContextualFragment(this.$t('analyticsCode').trim());
            document.head.append(gaScript);
        }
    },

    methods: {
        /**
         * switch to the map route and optionally pass it coordinates to show
         * @param  {Object|null} [coords=null] object with lng/lat
         */
        goToMap(coords = null, address = null, tutorial = false) {
            const query = {};

            // add coordinates to query string
            if (coords !== null && coords.type !== 'click') {
                const { lng, lat } = coords;
                query.lat = lat;
                query.lng = lng;

                // if there's no coords, there won't be an address
                if (address !== null) {
                    const { firstLine, secondLine, types } = address;
                    query.addressFirstLine = firstLine;
                    query.addressSecondLine = secondLine;
                    query.addressTypes = types;
                }
            } else {
                // if button was clicked with no geolocation coordinates
                // track analytics event
                window.gtag('event', 'explore-button-click', { action: 'Button', label: 'Explore the map' });
            }

            if (tutorial) {
                // add a tut param
                query.tut = true;
            }

            this.$router.push({
                name: 'map', // ಠ_ಠ goddamnit
                query,
            });
        },

        /**
         * On user geolocation error, show a message and tell them to try searching for their location
         */
        myLocationError() {
            EventBus.$emit('error', null, this.$t('errors.myLocationTrySearch'), false, {
                onAction: () => {
                    this.goToMap();
                },
            });
        },

        /**
         * Starts the tutorial
         */
        startTutorial() {
            this.goToMap(null, null, true);
        },

    },
};
</script>

<style lang="scss">
[data-aos="sticky-search-animation"] {
    opacity: 0;

    &.aos-animate {
        transition-property: opacity;
        opacity: 1;
    }
}

#home-page {
    $light-green: #e9fcf7;

    .sticky-search-container {
        position: sticky;
        top: 0;
        height: 0; // stops it taking up space at the top of the screen when it's hidden
        width: 100%;
        display: flex;
        margin: 0 auto;

        .field-body {
            width: 100%;
        }

        .button {
            font-size: 0.75rem;
            height: 2.5rem;
        }

        .geocoder-search {
            flex: 1;
        }
    }

    .map-splash-container {
        background: center $secondary url('../assets/HealthyPlace_bg.png');
        background-size: cover;
        height: 100vh;
        display: grid;
        grid-template-rows: auto 1fr auto 1fr;
        justify-items: center;

        .search-container {
            grid-row: 3/4;
            width: 90%;
            border-radius: 1.5rem;
            background-color: rgba(white, 0.8);

            // add space to accommodate mobile keyboard
            margin-bottom: 10rem;

            .header-icon{
                display: flex;
                position: relative;
                justify-content: center;

                img {
                    width: 25%;
                    padding-bottom: 1.5rem;
                }
            }

            .search-buttons {
                flex-direction: column;

                .tutorial-icon {
                        margin-right: 0;
                    }
            }

            .search-input-wrap {
                display: flex;
                justify-content: center;
                width: 100%;
                padding: 0 1rem;

                .geocoder-search {
                    width: 100%;
                    padding-left: 1.5vw;
                }

                .button {
                    box-shadow: 0 0 0.5rem rgba(black, 0.1);
                }

                .input {
                    box-shadow: 0 0 0.5rem rgba(black, 0.1);
                }

            }

            .button {
                box-shadow: 0 0 0.5rem rgba(black, 0.1);
            }
        }

        .explore {
            grid-row: 4/5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .info-section {
        padding-top: 2rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .info-header {
            width: 90%;
            margin-bottom: 6rem;
        }

        .metrics-container {
            display: flex;
            gap: 1rem;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0 2rem 2rem;

            .metric {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 1rem 0;

                .icon-container {
                    height: 4rem;
                    width: 4rem;
                    background-color: $light-green;
                    border-radius: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    @media screen and (min-width: 400px) {
        .sticky-search-container {
            padding: 0 1rem;
        }
    }

    @include tablet {
        .sticky-search-container {
            max-width: 52rem;

            .button {
                font-size: 1rem;
            }
        }

        .map-splash-container {
            .search-container {
                width: 30rem;

                .search-buttons {
                    flex-direction: row;
                }
            }
        }

        .info-section {

            .metrics-container {
                max-width: 50rem;
                .metric {
                    width: 20rem;
                }
            }
        }
    }

    @include desktop {


        .map-splash-container {
            .search-container {
                width: 43rem;
            }
        }

        .info-section {
            .metrics-container {
                max-width: 70rem;

                .metric {
                    width: 30rem;
                }
            }
        }
    }
}
</style>
