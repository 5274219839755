<template>
    <div class="slice rich-text-block">
        <prismic-rich-text
            class="content"
            :field="slice.primary.text_content"
        />
    </div>
</template>

<script>
export default {
    name: 'RichTextBlock',

    props: {
        slice: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">

</style>
