<template>
    <div
        id="internal-page"
        class="page"
        :class="pageId"
    >
        <page-header />

        <div class="container">
            <h1
                v-if="pageContent"
                class="title is-2"
                v-text="headerText"
            />

            <slice-zone
                v-if="pageContent"
                :slices="pageContent.data.body"
                :resolver="sliceResolver"
            />

            <div v-else>
                <b-skeleton
                    size="is-large"
                    width="25%"
                />
                <b-skeleton :count="5" />
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import SliceZone from 'vue-slicezone';
import RichTextBlock from '@/components/slices/RichTextBlock.vue';
import TextWithImage from '@/components/slices/TextWithImage.vue';
import EventBus from '@/eventBus';

import { availableLocales as cmsLocales } from '@/lang';

// Prismic content slices
const slices = {
    RichTextBlock,
    TextWithImage,
};

/*
 Internal content page (with "slice zones")
 Used for "Methods" and "Our Research" pages
*/

export default {
    name: 'InternalPage',

    components: {
        PageHeader,
        SliceZone,
    },

    data: () => ({
        pageId: '',
        pageContent: null,
    }),

    computed: {
        // header content as plain text
        headerText() {
            if (!this.pageContent) return null;

            return this.$prismic.asText(this.pageContent.data.title);
        },
    },

    watch: {
        $route() {
            // fetch localized content when the route changes
            this.loadContent();
        },
    },

    created() {
        // fetch localized content when the page is first created
        this.loadContent();
    },

    mounted() {
        if (this.$t('analyticsCode')) {
            // use the script element that CANUE has in prismic
            const gaScript = document.createRange().createContextualFragment(this.$t('analyticsCode').trim());
            document.head.append(gaScript);
        }
    },


    methods: {
        async loadContent() {
            try {
                // clear content, if any
                this.pageContent = null;
                this.pageId = this.$route.name;

                const lang = this.$i18n.locale; // "en" or "fr"
                const { cmsLocale } = cmsLocales[lang];

                // fetch localized content from the CMS
                this.pageContent = await this.$prismic.client.getByUID('internal_page', this.pageId, {
                    lang: cmsLocale,
                });
            } catch (error) {
                EventBus.$emit('error', error, this.$t('errors.apiCritical'), true);
            }
        },

        /**
         * Resolves a slice name from the CMS to a component in the @/slices directory
         *
         * @param {object} slice - slice object from Prismic's SliceZone component
         * @param {string} slice.sliceName - slice name to map to a component file
         * @returns {Component} - Vue component reference for the slice name
         */
        sliceResolver({ sliceName }) {
            return slices[sliceName];
        },
    },
};
</script>

<style lang="scss">
#internal-page {
    .container {
        padding: 1em;
    }
}
</style>
